<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title">退费申请--开发中
      <div class="hk_page_title_right">
        <el-button type="primary" size="medium" plain disabled @click="ifShowApply=true">导出</el-button>
      </div>
    </div>
    <div class="hk_page_wrap">
      <div class="hk-common-page">
        <div class="hk_search_box">
          <div class="hk_search_box_item">
            <!-- 搜索： -->

            <!-- <el-input
                            debounce size="small" clearable
                            placeholder="归属人" disabled
                            v-model="searchName">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </div>
                    <div class="hk_search_box_item"> 
                        <el-input
                            debounce size="small" clearable
                            placeholder="买家信息"
                            v-model="searchNickName">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </div>
                    <div class="hk_search_box_item">-->
            学习账号：<el-input debounce size="small" clearable placeholder="全拼手机号或后四位" class="hk-ipt-number" v-model.number="searchPhone">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>

          <div class="hk_search_box_item">
            付款时间：
            <el-date-picker v-model="searchTime" size="small" class="ipt-date-suffix" type="daterange" clearable range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </div>
          <div class="hk_search_box_item">
            <el-button type="primary" size="small" @click="searchEmployeeFun">搜索</el-button>
          </div>
        </div>
        <div class="hk_result_total_box">
          搜索共有 <span class="hk-font-highlight">{{ totalNum }}</span> 个退费成功订单
        </div>

        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="订单号" fixed="left" width="100px">
            <template slot-scope="scope">
              <span>{{scope.row.outTradeNo}}</span>
            </template>
          </el-table-column>

          <el-table-column label="归属人" width="100px">
            <template slot-scope="scope">
              <span>{{scope.row.assistantName}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="买家信息" width="120px">
                        <template slot-scope="scope">
                            <img :src="scope.row.headImg" alt="" style="width: 44px;height: 44px;border-radius: 50%;">
                            <span>{{scope.row.nickname}}</span>
                        </template>
                    </el-table-column> -->

          <el-table-column label="学习账号" width="120px">
            <template slot-scope="scope">
              <div>{{ scope.row.stuName }}</div>
              <span>{{scope.row.phone}}</span>
            </template>
          </el-table-column>
          <el-table-column label="商品信息" width="120px">
            <template slot-scope="scope">
              <span> {{ scope.row.productName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="应付金额" width="90px">
            <template slot-scope="scope">
              <span>{{(scope.row.amount + scope.row.giftAmount) /100}}</span>
            </template>
          </el-table-column>
          <el-table-column label="折扣优惠" width="90px">
            <template slot-scope="scope">
              <span>{{scope.row.giftAmount / 100}}</span>
            </template>
          </el-table-column>
          <el-table-column label="实付金额" width="90px">
            <template slot-scope="scope">
              <span>{{scope.row.amount/ 100}}</span>
            </template>
          </el-table-column>
          <el-table-column label="付款方式" width="100px">
            <template slot-scope="scope">
              <span>{{scope.row.payTypeStr}}</span>
            </template>
          </el-table-column>
          <el-table-column label="付款时间" width="110px">
            <template slot-scope="scope">
              <span>{{scope.row.payTime}}</span>
            </template>
          </el-table-column>
          <el-table-column label="授课老师" width="100px">
            <template slot-scope="scope">
              <span>{{scope.row.teacherName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="收货信息" width="120px">
            <template slot-scope="scope">
              <div>{{scope.row.receiveUser}} </div>
              <div>{{ scope.row.receivePhone}}</div>
            </template>
          </el-table-column>
          <el-table-column label="收货地址" width="120px">
            <template slot-scope="scope">
              <span>{{scope.row.receiveDetailAddress}}</span>
            </template>
          </el-table-column>
          <el-table-column label="商品备注" width="120px">
            <template slot-scope="scope">
              <span>{{scope.row.remark}}</span>
            </template>
          </el-table-column>
          <el-table-column label="订单状态" width="100px">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.refundErrMsg" placement="top-end" v-if="scope.row.orderStatus=='REFUND_FAIL'">
                <span class="hk-font-red">{{scope.row.orderStatusStr}}</span>
              </el-tooltip>
              <span class="hk-font-highlight" v-else-if="scope.row.orderStatus=='REFUND' || scope.row.orderStatus=='REFUND_APPLY'">{{scope.row.orderStatusStr}}</span>
              <span v-else>{{scope.row.orderStatusStr}}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" fixed="right" width="125px">
            <template slot-scope="scope">
              <span class="hk-table-option hk-table-option-first" @click="showRefundDetailFun(scope.row.stuId,scope.row.outTradeNo)">退费详情</span>
            </template>
          </el-table-column>

          <div slot="empty">
            <p>没有你要找的订单哦！</p>
          </div>
        </el-table>
        <div class="hk_pagination" v-if="totalNum>0">
          <el-pagination @current-change="handleCurPageChange" @size-change="handleSizeChange" :current-page.sync="curPage" :page-size="pageSize" :page-sizes="[10,20, 50, 100]" layout="total,sizes,prev, pager, next" :total="totalNum">
          </el-pagination>
          <!-- total,prev, pager, next -->
        </div>
      </div>

    </div>

    <!-- 退费详情 -->
    <el-dialog title="退费详情" :visible.sync="ifShowDetail" :modal-append-to-body="false" width="60%" @closed="closeDetailFun">
      <div class="o-dialog-box">
        <el-form label-width="120px" size="mini" ref="detailForm">
          <el-form-item label="操作人：">{{ detailInfo.refundUserName }}</el-form-item>
          <el-form-item label="退费时间：">{{ detailInfo.refundTime }}</el-form-item>
          <el-form-item label="实退金额：">{{ (detailInfo.refundAmount || 0)/100 }}</el-form-item>
          <el-form-item label="退费理由：">{{ detailInfo.refundRemark }}</el-form-item>
          <el-form-item label="退费失败原因：" v-if="detailInfo.orderStatus=='REFUND_FAIL'">{{ detailInfo.refundErrMsg }}</el-form-item>

        </el-form>
      </div>

    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      searchName: '', //搜索条件--归属人
      searchNickName: '',//搜索条件--买家昵称 几节课
      searchPhone: '', //搜索条件--学习手机号
      searchTime: [], //搜索条件--付款开始时间-结束时间

      tableData: [], //员工列表中显示的数据(搜索后,只显示符合条件的员工)
      pageSize: 10, //每页10条数据
      curPage: 1, //当前第几页
      totalNum: 0, //总共几条数据

      // totalFeeMoney:0, //订单总额
      ifShowDetail: false, //true-显示退费详情弹窗
      refundStuId: '', //退费订单的学员的id
      refundOutTradeNo: '', //退费订单的订单号
      detailInfo: {},//申请退款弹窗中，订单信息
      // billList:[], //申请退款弹窗中，课消记录

    }
  },
  mounted() {
    // this.getDataListFun() 
    // this.getTotalFee()
  },
  methods: {
    searchEmployeeFun() {
      this.curPage = 1
      this.tableData = []
      this.totalNum = 0
      this.getDataListFun()

    },
    //获取数据列表
    getDataListFun() {
      let that = this;
      let param = {
        pageNum: that.curPage,
        pageSize: that.pageSize,
        orderStatus: 'REFUND'

      };
      if (that.searchNickName && that.searchNickName.trim().length > 0) {
        param.nickname = that.searchNickName.trim() //买家信息
      }
      if (that.searchPhone) { //学习账号手机号
        param.phone = that.searchPhone
      }

      if (that.searchTime && that.searchTime[0]) {
        param.queryStartTime = that.searchTime[0].getTime()
        param.queryEndTime = that.searchTime[1].getTime();
      }

      that.tableData = [] //先将数据清空，避免视图不及时更新
      that.request({
        method: 'post',
        url: '/manage/1v1/listOrderByStaff',
        param: param,
        success: function (result) {
          if (result.code == 200) {
            let data = result.result;
            that.tableData = data.list || [];
            that.totalNum = Number(data.total);
          } else {
            console.log(result, '----退费订单列表not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取退费订单列表失败。')
          console.log(error, '-----fenye 获取退费订单列表失败')
        }
      })
    },
    //分页
    handleCurPageChange(val) {
      console.log(`当前页: ${val}`);
      this.curPage = val
      this.getDataListFun();
    },
    //修改每页展示的记录个数
    handleSizeChange(val) {
      console.log(`每页显示: ${val} 条数据`);
      this.pageSize = val
      this.curPage = 1
      this.getDataListFun();
    },

    //获取订单总收款金额
    getTotalFee() {
      let that = this;
      let param = {
      };
      // if(that.searchName && that.searchName.trim().length>0){
      //     param.staffName = that.searchName.trim()
      // }
      // if(that.searchPhone){
      //     param.phone = that.searchPhone
      // }
      that.request({
        method: 'post',
        url: '/manage/1v1/countAmountByStaff',
        param: param,
        success: function (result) {
          if (result.code == 200) {
            that.totalFeeMoney = (result.result || 0) / 100
          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取订单totalfee失败。')
          console.log(error, '-----totalfee失败')
        }
      })
    },

    //获取某个订单的详情
    getOrderDetail(stuId, outTradeNo) {
      let that = this;
      that.detailInfo = Object.assign({}, {})
      // that.billList = []
      that.request({
        method: 'post',
        url: '/manage/1v1/getOrderForRefund',
        param: {
          stuId: stuId,
          outTradeNo: outTradeNo
        },
        success: function (result) {
          if (result.code == 200 && result.result) {
            that.detailInfo = Object.assign({}, result.result.orderInfo)
            // that.billList = result.result.billList || []
          } else {
            console.log(result, '----listOrderByStaff  not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取listOrderByStaff失败')
          console.log(error)
        }
      })
    },


    //显示退费详情的弹窗，先调用接口获取“操作人、退费时间、实退金额、退费理由”，然后显示弹窗
    showRefundDetailFun(stuId, outTradeNo) {
      this.getOrderDetail(stuId, outTradeNo)
      this.ifShowDetail = true
    },
    closeDetailFun() {
      this.ifShowDetail = false
      this.detailInfo = Object.assign({}, {}) //数据清空
    },

  }
}
</script>

<style lang="scss" scoped>
.fee-box {
  .fee-item {
    display: flex;
    margin-bottom: 10px;
    .fee-add {
      border-radius: 4px;
      border: 1px dashed #e0e0e0;
      text-align: center;
      color: #e0e0e0;
      position: relative;
      cursor: pointer;
      flex: 1;
      &:hover {
        color: #f08d00;
        border-color: #f9d199;
      }
    }
  }
}
//退费弹窗
.o-dialog-box {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  .o-product-box {
    display: flex;
    align-items: flex-end;
    .pro-img {
      width: 44px;
      height: 44px;
      background: #ffbf00;
      border-radius: 2px;
      border: 1px solid #e0e0e0;
    }
    .pro-txt {
      padding-left: 10px;
      .pro-txt-name {
        font-weight: 600;
        font-size: 12px;
        color: #333333;
        height: 26px;
      }
      .pro-txt-fee {
        font-size: 10px;
        color: #f08d00;
        line-height: 18px;
      }
    }
  }
}
</style>